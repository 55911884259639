import React, {useState} from "react"
import Layout from "../components/layout";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import TopBanner from "../components/TopBanner";
import * as portfolioPageStyles from "../styles/single-portfolio-page.module.scss"
import SimpleHomePageCarousel from "../components/SlickCarousel";
import { Container } from "../components/Layout/Container";
import GalleryModule from "../components/Gallery/GalleryModule";
import { Section } from "../components/Layout/Section";
import { SectionTitle } from "../components/Layout/SectionTitle";


const SinglePressPage = ({pageContext, data}) => {
  return (
    <Layout insidePage breadCrumbLinks={pageContext.breadCrumb}>
      <Container width="full-size">
        <Section style={{marginTop: "75px"}}>
          <SectionTitle>
            {data.press.locales.find(l => l.iso === pageContext.lang).title}
          </SectionTitle>
          {/*<SimpleHomePageCarousel width="600px" height="576px" nodes={projects} />*/}
        </Section>
      </Container>
    </Layout>
  )
}

export const query = graphql`
    query($pressUid: String) {
        press(uid: {eq: $pressUid}) {
            uid
            hardCopyFile {
                publicURL
            }
            imageFile {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            locales {
                content
                iso
                metaDescription
                metaKeywords
                metaTitle
                title
                value
            }
        }
    }
`

export const Head = ({data, pageContext}) => {
  const { metaTitle, metaDescription} = data.press.locales.find(l => l.iso === pageContext.lang)
  return  <Seo title={metaTitle} description={metaDescription} />
}

export default SinglePressPage
